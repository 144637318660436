import Component from '../../libs/component-list';
import scope from '../../scope';

scope.components['showcase'] = Component.extend({
    name: 'showcase',
    ui: {
        list: '.showcase__list '
    },
    events: {
        'initialize.carousel @ui.list': 'initCarousel'
    },
    initCarousel: function(e, data){
        data.options.watchCSS = true;
    }
});

export default scope.components['showcase'];

